.grocery-product-quantity {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    padding-left: 10px;
}

.grocery-product-container {
    display: flex !important;
    justify-content: center !important;
}

.mobile-grocery-product-container {
    display: flex !important;
    width: 100%;
}

.grocery-product-quantity > svg {
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .ul-grocery {
        margin-right: 10px;
    }
}